<template>
  <toggle-section
    :is-open="isOpen[getSectionName('personassociates', index)]"
    :section="getSectionName('personassociates', index)"
    @toggle="toggleTable"
    chevron-position="right"
  >
    <template #titleSection>
      <div class="flex flex-grow items-center space-x-2">
        <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.PERSON_ASSOCIATES") }}</lf-h3>
        <lf-pill size="sm" color-index="15">
          {{ business.AssociateSection?.TotalPersonCount ?? 0 }}
        </lf-pill>
      </div>
    </template>
    <template #content>
      <div class="space-y-5">
        <div
          v-for="(associate, associateIndex) in business.AssociateSection
            ?.PersonAssociates?.PersonAssociate ?? []"
          :key="associateIndex"
          class="rounded-lg border"
        >
          <div class="flex items-center p-5 border-b justify-between">
            <div class="flex items-center space-x-2">
              <icon-base icon="user" class="-mt-5" />
              <div class="flex flex-col">
                <lf-h3>
                  {{ getName(associate.Name) }}
                </lf-h3>
                <div>
                  {{ associate.Role }}
                </div>
              </div>
            </div>
            <div class="flex space-x-4">
              <lf-pill color-index="15" size="sm">
                {{ associate.UniqueId }}
              </lf-pill>
            </div>
          </div>
          <div class="p-5 border-b flex space-x-2">
            <lf-pill
              :color-index="getDerogColorIndex(associate.HasDerog)"
              size="sm"
            >
              {{ associate.HasDerog ? "Has Derog" : "Derog Missing" }}
            </lf-pill>
            <lf-pill
              :color-index="getDeceasedColorIndex(associate.IsDeceased)"
              size="sm"
              :opaque="associate.IsDeceased"
            >
              {{ associate.IsDeceased ? "Deceased" : "Not Deceased" }}
            </lf-pill>
          </div>
          <div class="p-5 flex flex-row space-x-2 border-b">
            <icon-base icon="address" class="mt-1" />
            <div class="flex flex-col space-y-0.5">
              <div class="font-semibold text-headline">
                {{
                  associate.Address?.State
                    ? getStateName(associate.Address.State)
                    : "-"
                }}
              </div>
              <div>
                {{ cleanAndFormatAddress(associate.Address) }}
              </div>
            </div>
          </div>
          <div class="bg-gray-100 p-5 grid grid-cols-2">
            <div class="grid grid-cols-2 gap-2 pr-5 border-r">
              <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.SSN") }}</div>
              <div class="text-right text-headline">
                {{ associate.SSN }}
              </div>
            </div>
            <div class="grid grid-cols-2 gap-2 pl-5">
              <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.TO_DATE") }}</div>
              <div class="text-right text-headline">
                {{ `${associate.ToDate.Year}-${associate.ToDate.Month}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import type { Business } from "@/models/commercialData/lexisnexis/KybReport";
import {
  getSectionName,
  getName,
  getStateName,
  cleanAndFormatAddress
} from "@/helpers/lexisnexis";

defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};

const getDerogColorIndex = (HasDerog: boolean) => {
  return HasDerog ? "2" : "17";
};
const getDeceasedColorIndex = (IsDeceased: boolean) => {
  return IsDeceased ? "9" : "0";
};
</script>
